import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LANGUAGE } from '../../../core/data/setting.data';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { UtilityService } from '../../../core/utils/services/utility/utility.service';




@Component({
  selector: 'yellow-time-web-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  languageResult: any[];
  form: FormGroup;
  @Output() languageEvent = new EventEmitter<any>();
  constructor(private _fb: FormBuilder,
    private _uService: UtilityService,
    private _nService:NavigationService) {}

  ngOnInit(): void {
    this.languageResult = LANGUAGE;
    this.createFrom();
    this._nService.locale$.subscribe(x=>{
      if(this._uService.isNotEmptyObject(x) && this._uService.isNotNull(x)){
          this.form.setValue({language: this.languageResult.find(y=> y.value ==x)?.id || 1});
          this.languageEvent.emit(this.languageResult.find(y=> y.id == this.form.getRawValue().language)?.value);
      }
    });

  }

  createFrom() {
    this.form = this._fb.group({
      language: [''],
    })     
  }

  selectLanguage(event:any){
    let set = this.languageResult.find(x=> x.id == event.value)
    this.languageEvent.emit(set?.value);
  }

}
