export enum HTTPSTATUSCODE {
    Success = 200,
    Created = 201,
    NoResult = 204,
    UnAuthorized = 401,
    ValidationError = 422,
    BadRequest = 400,
    InternalServerError = 500
}

export enum HTTPSTATUS {
    Success = "SUCCESS",
    Failure = "FAILURE",
    Nodata = "NO DATA FOUND"
}

export enum PAGEACCESS {
    Read =  1,
    Write = 2,
    NoAccess = 0
}

export enum PAGEVIEW {
    CREATE = 1,
    EDIT = 2,
    READ = 3
}

export enum ACTION {
    CREATE =  "CREATE",
    EDIT = "EDIT",
    VIEW = "VIEW",
    COPY = "COPY"
}
