import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    private state = new BehaviorSubject<any>({} as any);
    notificationstate = this.state.asObservable();
    constructor(private http: HttpClient, @Inject(BASE_URL) private baseURL: string){}

    private mapstate(data:any) {
      var out = <any>{};
      if (data) {
          out.id = data?.id;
      }
      return out;
    }

    updatestate(data: any) {
      let set = this.mapstate(data);        
      this.state.next(set);
    }

  
    getNotifications(data:any,catergory:any) { 
      const url = this.baseURL + `notifications/list?pageNumber=${data.offset}&itemsPerPage=${data.limit}&notificationCategory=${catergory.statusId}&startDate=${catergory.startDate}&endDate=${catergory.endDate}`;
      return this.http.get<any>(`${url}`).pipe();
    }

    
    updateNotification(data:any) { 
      const url = this.baseURL + `notifications/update?notificationId=${data.notificationId}&action=${data.action}`;
      return this.http.patch<any>(`${url}`,data).pipe();
    }

    lookUpNotification() { 
      const url = this.baseURL + `notifications/lookup`;
      return this.http.get<any>(`${url}`).pipe();
    }
  
    readNotification(id:any) { 
      const url = this.baseURL + `notifications/read?notificationId=${id}`;
      return this.http.get<any>(`${url}`).pipe();
    }

    notificationCount(data:any) { 
      const url = this.baseURL + `notifications/count?startDate=${data.startDate}&endDate=${data.endDate}`;
      return this.http.get<any>(`${url}`).pipe();
    }
    

}
