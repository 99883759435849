<section class="">
    <div fxLayout="row wrap">
        <div fxFlex="70" fxLayout="column" class="p-3" fxFlex="45">
            <div [formGroup]="form">
                <mat-form-field appearance="outline"  [floatLabel]="'never'" class="example-full-width">
                    <mat-label>{{'COMMON.SELECTLANGUAGE' | translate}}</mat-label>
                    <mat-select formControlName="language" (selectionChange)="selectLanguage($event)" matInput>
                        <mat-option *ngFor="let val of languageResult" [value]="val.id">
                            {{val.name}}
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>expand_more</mat-icon>
                 </mat-form-field>
            </div>
        </div>
    </div>
</section>