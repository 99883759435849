import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'yellow-time-web-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  message: any;
  title: any;
  dataSource:any;
  key:false;
  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.dataSource = data;
  }

  ngOnInit(): void { 
    this.message = this.dataSource?.message;
    this.title = this.dataSource?.title;
    this.key = this.dataSource?.key || false;
  }


  onClose() {
    this.dialogRef.close();   
  }
}

