import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';

@Injectable({
    providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject(BASE_URL) private baseURL: string
  ) {}

  getUsers(data: any, searchTxt: string) {
    const url =
      this.baseURL +
      `users/list?pageNumber=${data.offset}&itemsPerPage=${data.limit}&searchquery=${searchTxt}`;
    return this.http.get<any>(`${url}`).pipe();
  }

  getUser(id: any) {
    const url = this.baseURL + `users/read?userId=${id}`;
    return this.http.get<any>(`${url}`).pipe();
  }

  createUser(body: any) {
    const url = this.baseURL + `users/create`;
    return this.http.post<any>(`${url}`, body).pipe();
  }

  updateUser(id: any, body: any) {
    const url = this.baseURL + `users/update?userId=${id}`;
    return this.http.put<any>(`${url}`, body).pipe();
  }

  deleteUser(id: any) {
    const url = this.baseURL + `users/delete?userId=${id}`;
    return this.http.delete<any>(`${url}`).pipe();
  }

  updateLanguage(language: any) {
    const url = this.baseURL + `users/update/language?language=${language}`;
    return this.http.put<any>(`${url}`,{}).pipe();
  }
  
}
