export enum UrlConfig {
  DASHBOARD = "/yellow-time/dashboard",
  EMPLOYEE_OVERVIEW = "/yellow-time/employees/overview",
  CUSTOMER_OVERVIEW = "/yellow-time/customers/overview",
  CREATE_CUSTOMER = "/yellow-time/customers/create-customer",
  VIEW_CUSTOMER = "/yellow-time/customers/view-customer",
  EDIT_CUSTOMER = "/yellow-time/customers/edit-customer",
  ADD_LOCATION = "/yellow-time/customers/create-location",
  EDIT_LOCATION = "/yellow-time/customers/edit-location",
  VIEW_LOCATION = "/yellow-time/customers/view-location",
  MANAGE_SPACE = "/yellow-time/customers/manage-space",
  VIEW_SPACE = "/yellow-time/customers/view-space",
  VIEW_USER = "/yellow-time/administration/view-user",
  CREATE_USER = "/yellow-time/administration/create-user",
  EDIT_USER = "/yellow-time/administration/edit-user",
  USER_OVERVIEW = "/yellow-time/administration/user-overview",
  EDIT_SPACE = "/yellow-time/customers/edit-space",
  ADD_SPACE = "/yellow-time/customers/add-space",
  MANAGE_ROLE = "/yellow-time/administration/manage-role",
  ROLES_VIEW = "yellow-time/administration/roles-overview",
  ORDER_OVERVIEW = "/yellow-time/maintenance/overview",
  MANAGE_CONTRACT="/yellow-time/maintenance/manage-contract",
  MANAGE_ORDERS="/yellow-time/maintenance/manage-orders",
  MESSAGE_VIEW = "/yellow-time/employees/message",
  MANAGE_CONFIG="/yellow-time/administration/configuration-overview",
  MANAGE_WORKLOCATIONS="/yellow-time/administration/worklocation-config",
  MANAGE_GROUPS="/yellow-time/administration/usergroup-config",
  MANAGE_NOTIFICATIONS="/yellow-time/administration/notification-config",
  DISPOSITION = "yellow-time/disposition",
  MANAGE_PROPERTY="/yellow-time/administration/property-config",
}  

