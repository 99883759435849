// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { map, Observable, of } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router: Router,private _navigationService: NavigationService,private _authService:AuthService,private _dialogRef: MatDialog) {
  }
  navigation:any;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
      if(this._authService.isLoggedIn()){
        return this._navigationService.hasAccess(state.url).pipe(map(res => {
              if (!res) {
                this._dialogRef.closeAll();
                this.router.navigate(['/auth/login']);
                return false;
              } else {       
                return true;
              }
        }));
      } else { 
        this._dialogRef.closeAll();
        this.router.navigate(['/auth/login']);
        return of(false);
      }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>   {
      if(this._authService.isLoggedIn()){ 
          if (!this._navigationService.checkAccess(state.url)) {
            this._dialogRef.closeAll();
            this.router.navigate(['/auth/login']);
            return of(false);
          } else {       
            return of(true);
          }
      } else {
          this._dialogRef.closeAll();
          this.router.navigate(['/auth/login']);
          return of(false);
      }
    }
}
