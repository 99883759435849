import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHeaders
} from "@angular/common/http";
import { throwError, Observable, BehaviorSubject, of, EMPTY } from "rxjs";
import { catchError, filter, finalize, take, switchMap, map, concatMap } from "rxjs/operators";
import { AuthService } from "../../services/auth/auth.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslatorService } from "../../utils/services/translator/translator.service";
import { HTTPSTATUS, PAGEACCESS, HTTPSTATUSCODE } from "../../utils/enum/enum";
import { IError } from "../../interface/shared/shared.interface";
import { AlertService } from "../alert/alert.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ALERT } from "../../data/shared.data";


@Injectable()
export class InterceptorService implements HttpInterceptor {
  private AUTH_HEADER = "Authorization";
  private token = "atkyttkn";
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(private _xService:TranslatorService,
              private _sService: NgxSpinnerService,
              private _dialogRef: MatDialog,
              private _aService:AuthService,
              private _alertService: AlertService ) {

  }

  private checkUrl(url: string): boolean {
    const pattern = /^https?:\/\//i;
    return pattern.test(url);
  }

  private getUrl(url: string): string {
    url = this.checkUrl(url) ? url : '';
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

  addHeader(): HttpHeaders {
    const headers = new HttpHeaders()
    .set('Accept', 'application/json'||'application/pdf')
    .set('Content-Type' , 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Accept-Encoding', 'gzip')
    return headers;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes("")) {
      return next.handle(req);
    }

    if(req.url.indexOf('assets') > -1){
      return next.handle(req);
    }

    req = req.clone({ url: this.getUrl(req.url) });
    req = this.addAuthenticationToken(req);

    const urlWithoutParams = new URL(req.url).pathname;
    if(urlWithoutParams.includes('delete')){
      return this._alertService.showConfirm(ALERT[0]).pipe(
        concatMap( (val) =>{ if(val)  {
          this._sService.show();
          return this.onHandleProcess(req,next);
        } else {
          return EMPTY
        }})
      );
    }

    if((req.url?.indexOf('schedule/update') > -1) && req?.body?.orderTaskId > 0 && req?.body?.hasVacation==true){
      if(req?.body?.originalEmployeeId != req?.body?.targetEmployeeId){
          let data =  ALERT[1];
          data.message = this._xService.instant(ALERT[1].message,req?.body?.targetEmployeeName);
          return this._alertService.showConfirm(data,true).pipe(
            concatMap( (val) =>{
              let body = req?.body;
              this._sService.show();
              if(val) {
                  body.jobType = 1;
                  let request = req.clone({ body: body });
                  return this.onHandleProcess(request,next);
              } else {
                  body.jobType = 0;
                  let request = req.clone({ body: body });
                  return this.onHandleProcess(request,next);
              }
          })
        );
      }
    }



    if((req.url?.indexOf('create') > -1) || (req.url?.indexOf('update') > -1) || (req.url?.indexOf('publish') > -1)){
      this._sService.show();
    }
    return this.onHandleProcess(req,next);
  }

  onHandleProcess(
    req: HttpRequest<any>,
    next: HttpHandler):Observable<HttpEvent<any>>{
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if ((event.url?.indexOf('create') > -1) || (event.url?.indexOf('update') > -1) || (event.url?.indexOf('delete') > -1) || (req.url?.indexOf('publish') > -1)) {
              this._sService.hide();
            if ((event?.status == HTTPSTATUSCODE.Created || event?.status == HTTPSTATUSCODE.Success) && event?.body?.status == HTTPSTATUS.Success) {
              this._xService.notify(event?.body?.message, HTTPSTATUS.Success);
            } else if (event?.status == HTTPSTATUSCODE.InternalServerError && event?.body?.message == HTTPSTATUS.Failure) {
              this._xService.notify(event?.body?.message, HTTPSTATUS.Failure);
            } else if (event?.status == HTTPSTATUSCODE.ValidationError && event.url?.indexOf('delete') > -1) {
              let error = event?.body?.message;
              new Error(JSON.stringify(this.getMessage(error)));
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === HTTPSTATUSCODE.UnAuthorized) {
           if(!(error.url?.indexOf("refreshtoken") > -1)){
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              return this.refreshAccessToken().pipe(
                switchMap((set: any) => {
                  this._aService.setSession(localStorage.getItem('atktkgyt') != null, { 'aTKN': set?.accessToken, 'rTKN': set?.refreshToken });
                  this.refreshTokenSubject.next(set?.accessToken);
                  return next.handle(this.addAuthenticationToken(req));
                }),
                catchError((err) => {
                  this.refreshTokenInProgress = false;
                  this._aService.clearSession();
                  this._dialogRef.closeAll();
                  this._aService.logout();
                  return throwError(() => new Error());
                }),
                finalize(() => (this.refreshTokenInProgress = false))
              );
            }
           } else {
            this.refreshTokenInProgress = false;
            this._aService.clearSession();
            this._dialogRef.closeAll();
            this._aService.logout();
            return throwError(() => new Error());
           }

        } else if (error && error.status === HTTPSTATUSCODE.ValidationError) {
          this._sService.hide();
          return throwError(error);
        }else if (error && error.status === HTTPSTATUSCODE.InternalServerError) {
          this._xService.notify('ERROR.INTERNAL_SERVER', HTTPSTATUS.Failure);
          this._sService.hide();
          return throwError(() => new Error());
        }
        else {
          this._sService.hide();
          return throwError(() => new Error());
          //return throwError(() => new Error(JSON.stringify(this.getMessage(error))));
        }
      })
    );
  }

  private getMessage(error:any): any{
    let message = <IError>{};
            switch (error.status) {
              case 403:
                message.title = 'ERROR.FTITLE';
                message.message = 'ERROR.FMESSAGE';
                break;
              case 404:
                message.title = 'ERROR.ETITLE';
                message.message = 'ERROR.EMESSAGE';
                break;
              case 500:
                message.title = 'ERROR.ETITLE';
                message.message = 'ERROR.EMESSAGE';
                break;
               case 422:
                message.title = 'ERROR.WTITLE';
                message.message = error.error.message ?? 'ERROR.WMESSAGE';
                break;

          }
        return message;
  }

  private refreshAccessToken(): Observable<any> {
    return this._aService.getRefreshToken();
  }
  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    this.token = this._aService.getAccessTkn();
    if (!this.token) {
      return request;
    }

    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.token)
    });
  }
}
