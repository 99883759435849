

export const LANGUAGE = [
    {
        id:1,
        name:"Deutsch",
        value:"de-DE"
    },
    {
        id:2,
        name:"English",
        value:"en-EN"
    },
];

