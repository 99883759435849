export const MenuList = [
    {
        id:1,
        label: 'MENU.DASHBOARD',
        groupId:1,
        icon:"dashboard",
        url:"/yellow-time/dashboard",
        isactive:false,
        path:'dashboard',
        access: 2,
        sortOrderId:1,
        children: [],
        routes:[],
    },
    {
        id:2,
        label:'MENU.EMPLOYEES',
        groupId:2,
        icon:"people_alt",
        url:"/yellow-time/employees/overview",
        isactive:false,
        path:'employees',
        access: 2,
        sortOrderId:2,
        routes:[],
        children: [
        {
            id:2,
            label:'MENU.MANAGEEMPLOYEES',
            groupId:2,
            // icon:"icon_emp",
            url:"/yellow-time/employees/overview",
            isactive:false,
            access: 2,
            routes:[],
        },
        {
            id:3,
            label:'MENU.GPSLOCATIONS',
            groupId:2,
            // icon:"icon_emp",
            url:"/yellow-time/employees/gps-locations",
            isactive:false,
            access: 2,
            routes:[]
        },
        {
            id:4,
            label:'MENU.MESSAGES',
            groupId:2,
                // icon:"icon_emp",
            url:"/yellow-time/employees/message",
            isactive:false,
            access: 2,
            routes:[]
        },
        {
            id:14,
            label:'MENU.VACATIONAPPROVAL',
            groupId:2,
                // icon:"icon_emp",
            url:"/yellow-time/employees/vacation-overview",
            isactive:false,
            access: 2,
            routes:[]
        }
        ]
    },
    {
        id:5,
        label:'MENU.CUSTOMERS',
        groupId: 3,
        icon:"store",
        url:"/yellow-time/customers/overview",
        isactive:false,
        path:'customers',
        access: 2,
        sortOrderId:3,
        children:[],
        routes:[
            { url:"/yellow-time/customers/create-customer", access: 2},
            { url:"/yellow-time/customers/edit-customer", access: 2 },
            { url:"/yellow-time/customers/view-customer", access: 2 },
            { url:"/yellow-time/customers/create-location",access: 2 },
            { url:"/yellow-time/customers/edit-location", access: 2 },
            { url:"/yellow-time/customers/view-location",access: 2 },
            { url:"/yellow-time/customers/add-space",access: 2 },
            { url:"/yellow-time/customers/edit-space",access: 2 },
            { url:"/yellow-time/customers/view-space",access: 2 },
        ],
        
    },
    {
        id:9,
        label:'MENU.MAINTENANCECLEANING',
        groupId: 6,
        icon:"maps_home_work",
        url:"/yellow-time/maintenance/overview",
        isactive:false,
        path:'maintenance',
        access: 2,
        sortOrderId:4,
        children:[],
        routes:[
            { url:"/yellow-time/maintenance/manage-orders", access: 2 },
            { url:"/yellow-time/maintenance/manage-contract", access: 2 }
        ],
    },
    {
        id:6,
        label:'MENU.DISPOSITION',
        groupId: 4,
        icon:"event_note",
        url:"/yellow-time/disposition",
        isactive:false,
        path:'disposition',
        access: 2,
        sortOrderId:5,
        children: [],
        routes:[],
    },
    {
        id:10,
        label:'MENU.REPORTS',
        groupId: 7,
        icon:"inventory_2",
        url:"/yellow-time/reports/generate-timesheet",
        isactive:false,
        path:'reports',
        access: 2,
        sortOrderId:6,
        routes:[],
        children: [
            {
                id:10,
                label: 'MENU.TIMESHEET',
                groupId: 7,
                // icon:"icon_emp",
                url:"/yellow-time/reports/generate-timesheet",
                isactive:false,
                access: 2,
                routes:[]
            },
            {
                id:11,
                label: 'MENU.WORKSHEET',
                groupId: 7,
                // icon:"icon_emp",
                url:"/yellow-time/reports/generate-worksheet",
                isactive:false,
                access: 2,
                routes:[]
            },
            {
                id:12,
                label: 'MENU.CLIENTSHEET',
                groupId: 7,
                // icon:"icon_emp",
                url:"/yellow-time/reports/generate-clientsheet",
                isactive:false,
                access: 2,
                routes:[]
            }
        ]
    },
    {
        id:7,
        label:'MENU.ADMINISTRATION',
        groupId: 5,
        icon:"admin_panel_settings",
        url:"/yellow-time/administration/users-overview",
        isactive:false,
        path:'administration',
        access: 2,
        sortOrderId:7,
        routes:[],
        children: [
            {
                id:7,
                label: 'MENU.MANAGEUSERS',
                groupId: 5,
                icon:"icon_emp",
                url:"/yellow-time/administration/users-overview",
                isactive:false,
                access: 2,
                routes:[]
            },
            {
                id:8,
                label: 'MENU.MANAGEROLES',
                groupId: 5,
                icon:"icon_emp",
                url:"/yellow-time/administration/roles-overview",
                isactive:false,
                access: 2,
                routes:[
                    { url:"/yellow-time/administration/manage-role", access: 2},
                ]
            },
            {
                id:13,
                label: 'MENU.MANAGEDEVICE',
                groupId: 5,
                icon:"icon_emp",
                url:"/yellow-time/administration/device-overview",
                isactive:false,
                access: 2,
                routes:[
                    { url:"/yellow-time/administration/manage-device", access: 2},
                ]
            },
            {
                id:15,
                label: 'MENU.MANAGESETTINGS',
                groupId: 5,
                icon:"icon_emp",
                url:"/yellow-time/administration/configuration-overview",
                isactive:false,
                access: 2,
                routes:[
                    { url:"/yellow-time/administration/worklocation-config", access: 2},
                    { url:"/yellow-time/administration/usergroup-config", access: 2},
                    { url:"/yellow-time/administration/notification-config", access: 2},
                    { url:"/yellow-time/administration/property-config", access: 2},
                ]
            }
        ]
    }
  ];
  