import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AlertService } from '../../../generic/alert/alert.service';
import { HTTPSTATUS } from '../../enum/enum';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {   
    languages:any = ['en-EN','de-DE']; 
    private currentLanguage: string;
    constructor(private _xService: TranslateService, @Inject(LOCALE_ID) private locale: string,private _aService:AlertService) {}
    public setDefaultLocale(): void {
        if(this.languages.indexOf(localStorage.getItem('language'))>-1){
            this._xService.setDefaultLang(localStorage.getItem('language'));
            this._xService.use(localStorage.getItem('language'));
            moment.locale(localStorage.getItem('language'));
            this.currentLanguage = localStorage.getItem('language');
        } else {
          this._xService.addLangs(this.languages);
          this._xService.setDefaultLang(this.locale);
          this._xService.use(this.locale)
          moment.locale(this.locale);
          localStorage.setItem('language',this.locale);
          this.currentLanguage = this.locale;
        }
        moment.updateLocale('de',{
          monthsShort:'Jan_Feb_März_Apr_Mai_Jun_Jul_Aug_Sept_Okt_Nov_Dez'.split('_')
        });
    }

    getLocale(): string {
      return this.currentLanguage;
    }

    public changeLanguage(language: string = null): void {
      this._xService.addLangs(this.languages);
      moment.locale(language)
      this._xService.use(language)
      this.currentLanguage = language;
      if(language === "de-DE"){
        moment.updateLocale('de',{
          monthsShort:'Jan_Feb_März_Apr_Mai_Jun_Jul_Aug_Sept_Okt_Nov_Dez'.split('_')
        });
      }else{
        moment.updateLocale('en', {
          monthsShort: [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ],
        });
      }
      localStorage.setItem('language', language);
    }

    public notify(key:any,type:HTTPSTATUS){
      this._xService.get(key).subscribe((x:any)=>{
        if(type == HTTPSTATUS.Success){
          this._aService.showSuccess(x,'')
        }else{
          this._aService.showError(x,'')
        }
      });
    }

    public instant(key:string,value:string){
      return this._xService.instant(key,{name:value });
    }
}

