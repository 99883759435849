import { Component } from '@angular/core';
import { TranslatorService } from './core/utils/services/translator/translator.service';


@Component({
  selector: 'yellow-time-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'yellow-time';
  constructor(private _translate:TranslatorService) { 
    this._translate.setDefaultLocale()
  }
}
