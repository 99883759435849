import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { UtilityService } from '../../core/utils/services/utility/utility.service';
import { SettingsComponent } from '../../module/settings/settings.component';
import { BehaviorSubject, filter, interval, mergeMap, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { NotificationService } from '../../core/services/notification/notification.service';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { AuthService } from '../../core/services/auth/auth.service';
import { WebSocketService } from '../../core/services/websocket/websocket.service';

@Component({
  selector: 'yellow-time-web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  navigation: Array<any>;
  frequency: Subscription;
  @Output() backDropClick = new EventEmitter<any>();
  modules: any = ['employees', 'reports', 'administration'];
  interval: any = 60000;
  isActive: boolean = false;
  url: any = '';
  private routeUrl$: Observable<UrlSegment[]>;
  private routeUrlSubscription: Subscription;
  dataSource: any =[];
  showHeader:boolean = true;
  userName:any;
  showBanner:boolean = false;
  count : number
  constructor(private _nService: NavigationService,
    private _service: NotificationService,
    private _wservice:WebSocketService,
    private _aservice: AuthService,
    private cdr: ChangeDetectorRef,
    private _uService: UtilityService,
    private _dialogRef: MatDialog,
    private _router: Router,
    private _arouter: ActivatedRoute
  ) {
    this.routeUrl$ = this._router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      switchMap(() => {
        const url = this._arouter.firstChild?.url;
        return url || of();
      })
    );
    this.routeUrlSubscription = this.routeUrl$.subscribe((url) => {
      this.url = url.join();
      this.checkActive();
    });
    this._wservice.connect();
  }

  ngOnInit(): void {
    this._nService.state$.subscribe(x => {
      if (this._uService.isNotEmptyObject(x) && this._uService.isNotNull(x)) {
        this.navigation = x.state;
      }
    })

    this._nService.mode$.subscribe(x => {
      if(this._uService.isNotEmptyObject(x) && this._uService.isNotNull(x)){
        this.showHeader = x.display;
      }
    });

    this._nService.user$.subscribe(x=>{
      if(this._uService.isNotEmptyObject(x) && this._uService.isNotNull(x)){
        this.userName = x.name;
      }
    })
    this._wservice.incoming$.subscribe(x=>{
      if(x.type == 'notification'){
       this.dataSource = x?.data || [];
       this.count = x.count;
      //  this.playSound();
      }
    })
    this.checkActive();
  }


  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngAfterContentInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this._uService.isNotNull(this.frequency)) {
      this.frequency.unsubscribe();
    }
    this.routeUrlSubscription.unsubscribe();
  }

  onLogout() {
    this._wservice.disconnect();
    this._aservice.clearSession();
    this._dialogRef.closeAll();
    this._router.navigateByUrl('/auth/login');
  }
  onViewBanner(){
    this.showBanner = !this.showBanner;
    this._nService.setbannermode(this.showBanner);
    }

  onSetting() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.height = '400px';
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {
      title: "Info",
      message: ""
    }
    this._dialogRef.open(SettingsComponent, dialogConfig).afterClosed().subscribe(data => {
    });
  }


  checkActive() {
    this.navigation?.map(item => { item.isactive = false; });
    if (this.navigation?.length > 0) {
      let index = this.navigation.findIndex(x => x.path == this.url)
      if (this.url.includes('dashboard')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('employees')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('reports')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('administration')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('customers')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('maintenance')) {
        this.navigation[index].isactive = true;
      } else if (this.url.includes('disposition')) {
        this.navigation[index].isactive = true;
      }
    }
  }

  onNotification() {
    this.backDropClick.emit();
  } 

  playSound(){
    let audio = new Audio()
    audio.src = "../assets/audio/Notification.mp3"
    audio.load();
    audio.play();
  }


}
