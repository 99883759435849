import {InjectionToken} from '@angular/core';
import { IEnvironment } from './environment.interface';

/**
 * @const ENVIRONMENT
 * Injection token for the environment interface to be provided by the applications.
 */
export const BASE_URL: InjectionToken<IEnvironment> = new InjectionToken('BASEURL');
export const WEBSOCKET_URL: InjectionToken<IEnvironment> = new InjectionToken('WEBSOCKET_URL');
export const SECRET_KEY: InjectionToken<IEnvironment> = new InjectionToken('SECRETKEY');
export const LOCALE: InjectionToken<IEnvironment> = new InjectionToken('LANGUAGE');



