import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorDialogComponent } from '../../../module/shared/error-dialog/error-dialog.component';


@Injectable({
 providedIn: 'root'
})
export class ErrorService implements ErrorHandler {  
    isOpen: boolean = false;
    constructor(public dialog: MatDialog) { }
    
    openDialog(data): any {
        this.isOpen = true;
        const dialogRef = this.dialog.open(ErrorDialogComponent, {
            width: '600px',
            data: data
        });
        dialogRef.afterClosed().subscribe(result => {
            this.isOpen = false;
        });
    }

    handleError(error:any) {
        if(error.url?.indexOf('delete') > -1){
            const omessage = this.processError(error);
            if(!this.isOpen){
                const [first] = omessage?.error?.data;
                let out = {
                    message: first?.message || 'ERROR.FMESSAGE',
                    title: 'ERROR.FTITLE',
                    key: true
                }
                this.openDialog(out);
            }
        }
    }

    private processError(error: Error | HttpErrorResponse): any {
        const oerror: any = {
          error:null,
          message: error.message,
          details: []
        };
        if (error instanceof Error) {
            oerror.details.push(error.stack);
        } else if (error instanceof HttpErrorResponse) {
            oerror.details = Object.keys(error)
            .map((key: string) => `${key}: ${error[key]}`);
            oerror.error = error?.error;
        } else {
            oerror.details.push(JSON.stringify(error));
        }
        return oerror;
      }

}


