import { Injectable } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TranslationResolverService  {
  constructor(private translateService: TranslateService) {}

  resolve(): Observable<any> {
    return this.translateService.getTranslation(
      this.translateService.currentLang
    );
  }
}