import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from '../core/services/navigation/navigation.service';

@Component({
  selector: 'yellow-time-web-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  sideBar:boolean;
  isFullScreen: boolean=false;
  constructor(
    private _nService: NavigationService,
  ) {
  }

  ngOnInit(): void {
    this._nService.mode$.subscribe(x=>{
      this.isFullScreen = !(x?.display);
    })
  }

  onToggle() {
    this.sideBar=true;
    setTimeout(()=>{
      this.sideBar=false;
    },500);
  }


}
