<section class="notification-content">
    <div fxFlex="100" class="p-3" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <label fxFlex="100" class="text-left title-text pl-0">Notification</label>
            <mat-icon (click)="onClose()">close</mat-icon>
        </div>
        <div fxLayout="column">
            <div class="notification-card" *ngFor="let data of dataSource;let i=index;">
                <div fxFlex="100" fxFlexAlign="space-between start" fxLayout="row" fxLayoutGap="5px" (click)="onRead(data,'Read')">
                    <div fxLayout="column" fxFlex="90">
                        <label class="field-name">{{data.notificationType}}</label>
                        <p class="field-value">{{data.notificationDetails}}</p>
                    </div>
                    <mat-icon>navigate_next</mat-icon>
                </div>
            </div>
        </div>
    </div>
</section>