export const ALERT = [
    {
       id:1,
       title: "INFODIALOG.DELETE.TITLE",
       message: "INFODIALOG.DELETE.MESSAGE",
   },
   {
       id:2,
       title: "INFODIALOG.TASKDRAG.TITLE",
       message: "INFODIALOG.TASKDRAG.MESSAGE",
   }
  ]