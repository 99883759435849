import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { InfoDialogComponent } from '../../../module/shared/info-dialog/info-dialog.component';



@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private toastr: ToastrService, public dialog: MatDialog) { }
   
  showSuccess(message:any, title:any){
      this.toastr.success(message, title)
  }
   
  showError(message:any, title:any){
      this.toastr.error(message, title)
  }
   
  showInfo(message:any, title:any){
    this.toastr.info(message, title)
  }
   
  showWarning(message:any, title:any){
      this.toastr.warning(message, title)
  }

  showConfirm(data:any,out?:boolean):Observable<any>{
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '500px',
      data: { ...data, actionName: out },
    });
    return dialogRef.afterClosed();
  }
}
