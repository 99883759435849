<div fxLayout="column" class="top-banner" *ngIf="showBanner">
    <div fxFlex="100" fxFlexAlign="space-between center" fxLayout="row" fxLayoutGap="5px">
        <div fxLayout="column" fxFlex="90" fxLayoutAlign="center center">
            Header Content
        </div>
        <div fxLayout="column" fxFlex="10" fxLayoutAlign="space-around end">
            <mat-icon (click)="onViewBanner()">close</mat-icon>
        </div>
    </div>
</div>
<section class="header" *ngIf="showHeader">
    <mat-toolbar>
        <mat-toolbar-row class="top-row">
            <!-- <a><img src="../../../assets/images/brand.svg" width="140"></a> -->
            <a><img src="../../../assets/images/brand.jpg" width="110"></a>
            <span class="example-spacer"></span>
            <button mat-mini-fab class="notification-button" (click)="onNotification()">
                <mat-icon [ngClass]="{'bell':dataSource.length > 0}" class="i-21 m-0">notifications</mat-icon>
                    <span class="notification-count" *ngIf="dataSource.length > 0">{{count}}</span>
            </button>
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon class="i-32">account_circle</mat-icon>{{userName}}
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="onSetting()">{{'AUTH.SETTINGS' | translate}}</button>
                <button mat-menu-item (click)="onLogout()">{{'AUTH.LOGOUT' | translate}}</button>
            </mat-menu>
        </mat-toolbar-row>
        <mat-toolbar-row class="nav-row">
            <div fxLayout="row" fxLayoutGap="30" fxFlex="100">
                <ng-container *ngFor="let menu of navigation;index as menuindex;">
                    <ng-container *ngIf="menu.children.length == 0 && menu.access != 0">
                        <a class="nav-link" routerLink="{{menu.url}}" [class.active]="menu.isactive" >
                            <mat-icon class="i-21">{{menu.icon}}</mat-icon>
                            <!-- <span class="icon fa" [ngClass]="menu.icon"></span> -->
                            <span class="text-holder">{{menu.label | translate}}</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="menu.children.length > 0 && menu.access != 0">
                        <a [matMenuTriggerFor]="levelOne" #levelOneTrigger="matMenuTrigger" routerLinkActive="active" [class.active]="menu.isactive" (click)="levelOneTrigger.openMenu()">
                            <mat-icon class="i-21">{{menu.icon}}</mat-icon>
                            <!-- <span class="icon fa" [ngClass]="menu.icon"></span> -->
                            <span class="text-holder" >{{menu.label | translate}}</span>
                            <mat-icon class="drop">keyboard_arrow_down</mat-icon>
                        </a>
                        <mat-menu #levelOne="matMenu" direction="down" yPosition="below">
                            <span (mouseleave)="levelOneTrigger.closeMenu()">
                                <ng-container *ngFor="let submenu of menu.children">
                                    <span class="sub px-0" *ngIf="!submenu.children && submenu.access != 0" mat-menu-item>
                                        <a class="nav-link px-4 py-0" routerLink="{{submenu.url}}" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">{{submenu.label | translate}}
                                            <i *ngIf="submenu.icon" [ngClass]="submenu.icon"></i>
                                        </a>
                                    </span>
                                </ng-container>
                            </span>
                        </mat-menu>
                    </ng-container>
                </ng-container>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</section>






