import { HttpClient } from '@angular/common/http';
import {  Inject, Injectable } from '@angular/core';
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';
import { BehaviorSubject } from 'rxjs';
import { ICurrentState, IState } from '../../interface/router/route.interface';


@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private ouser = new BehaviorSubject<any>({} as any);
    $user = this.ouser.asObservable();

    private ocurrentstate = new BehaviorSubject<ICurrentState>({} as any);
    currentstate$ = this.ocurrentstate.asObservable();

    constructor(private http: HttpClient,@Inject(BASE_URL) private baseURL: string) {}

    
    private mapcurrentstate(data:any) : any{
        var out = <ICurrentState>{};
        out.access = data;
        return out;
    }
  
    setcurrentstate(data: any) {
        let set = this.mapcurrentstate(data);
        this.ocurrentstate.next(set);
    }

    getWorkLocations() { 
      const url = this.baseURL + `shared/office/lookup`;
      return this.http.get<any>(`${url}`).pipe();
    }

    getStates(id:any) { 
      const url = this.baseURL + `/lookup/value?value=state&id=${id}`;
      return this.http.get<any>(`${url}`).pipe();
    }

    getCountries() { 
      const url = this.baseURL + `/lookup/value?value=country`;
      return this.http.get<any>(`${url}`).pipe();
    }
}