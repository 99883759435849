import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './core/generic/translate/translate.factory';
import { GoogleMapsModule } from '@angular/google-maps';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorService } from './core/generic/interceptor/interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { WEBSOCKET_URL,BASE_URL, SECRET_KEY } from '../environments/environment.factory';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SettingsModule } from './module/settings/settings.module';
import { NotificationComponent } from './layout/notification/notification.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorService } from './core/generic/error/error.service';
import { MAT_LEGACY_TABS_CONFIG as MAT_TABS_CONFIG } from '@angular/material/legacy-tabs';
import { TranslatorService } from './core/utils/services/translator/translator.service';
registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [AppComponent, LayoutComponent, HeaderComponent, NotificationComponent, SidebarComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexModule,
    FlexLayoutModule,
    RouterModule,
    AppRoutingModule,
    LayoutModule,
    GoogleMapsModule,
    SettingsModule,
    NgxMatTimepickerModule.setLocale(environment.locale),
    NgxSpinnerModule.forRoot({ type: 'timer' }),
    ToastrModule.forRoot({
      preventDuplicates: false,
      progressBar: true,
      countDuplicates: true,
      extendedTimeOut: 3000,
      positionClass: 'toast-bottom-right',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    })
  ],
  exports: [MaterialModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: ErrorHandler, useClass: ErrorService },
    { provide: BASE_URL, useValue: environment.url },
    { provide: WEBSOCKET_URL, useValue: environment.sUrl },
    { provide: SECRET_KEY, useValue: environment.sKey },
    { provide: LOCALE_ID, useValue: environment.locale },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: MAT_DATE_LOCALE, useFactory: (translatorService: TranslatorService) => translatorService.getLocale(), deps: [TranslatorService]  },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {}
 }
