import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { interval, mergeMap, Subject, Subscription } from 'rxjs';
import { NotificationService } from '../../core/services/notification/notification.service';
import { HTTPSTATUS } from '../../core/utils/enum/enum';
import { UtilityService } from '../../core/utils/services/utility/utility.service';
import { WebSocketService } from '../../core/services/websocket/websocket.service';
import { UrlConfig } from '../config/route.config';
import { Router } from '@angular/router';
import { oNotification } from '../../core/interface/notification/notification.interface';

@Component({
  selector: 'yellow-time-web-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  dataSource: any;
  notificationId: any;
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  onotification:oNotification = <oNotification>{notificationId: 0, action:''}

  constructor(
    private _nService: NotificationService,
    private _uService: UtilityService,
    private _wservice:WebSocketService,
    private _router: Router,
  ) {

  }

  ngOnInit(): void {
    this._wservice.incoming$.subscribe(x=>{
      if(x.type == 'notification'){
       this.dataSource = x?.data || [];  
      }
    })
  }

  onReadNotification(data:any,option:any){
    this.onotification.notificationId = data?.webNotificationId;
    this.onotification.action = option;
    this._nService.updateNotification(this.onotification).subscribe((res: any) => {
      if (this._uService.isNotEmptyObject(res) && res?.status?.toUpperCase() == HTTPSTATUS.Success) {
        // this.dataSource.splice(index, 1);
        this._router.navigate([UrlConfig.DASHBOARD]); 
        this.closeEvent.emit(true)
      }
    });
  }
  
  onRead(data:any,type:any) {
    this.onReadNotification(data,type);
  }

  onClose(){
    this.closeEvent.emit(true);
  }
}




