<h2 mat-dialog-title>{{title | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <div fxFlex="100" class="error" fxLayoutAlign="left center" fxLayout="row">
        <mat-icon class="me-2">warning</mat-icon>
        <p class="m-0" *ngIf="key"> {{message | translate}}</p>
        <p class="m-0" *ngIf="!key"> {{message}}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="action-btn-bg">
    <button [mat-dialog-close]="true" mat-raised-button color="secondary">
        {{'COMMON.CANCEL' | translate}}</button>
</mat-dialog-actions>