import { Injectable } from "@angular/core";

@Injectable()
export class MatDateFormats{    
    constructor(){}    
    deDateInput = {
            dateInput: 'DD.MM.YYYY',
            monthYearLabel: 'YYYY',
        };
    enDateInput = {
            dateInput: 'DD.MM.YYYY',
            monthYearLabel: 'YYYY',
        };
    
    get display(){
      return this.getMatDateFormat()
    }    

    get parse(){
        return this.getMatDateFormat();
    }

    private getMatDateFormat() {
        return localStorage.getItem('language')&&localStorage.getItem('language').match('en-EN') ? this.enDateInput : this.deDateInput;
    }

  }