import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs'; 
import {  map } from 'rxjs/operators';
import { IBanner, IFullScreen, ILanguage, IState, IUserDetails } from '../../interface/router/route.interface';
import { MenuList } from '../../../layout/config/menu.config';
import { SharedService } from '../shared/shared.service';
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';
import { HTTPSTATUS } from '../../utils/enum/enum';
import { UtilityService } from '../../utils/services/utility/utility.service';
import { TranslatorService } from '../../utils/services/translator/translator.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService { 
    stateurl:any;
    defaultLocale:any = {language: "de-DE"}

    private olocale = new BehaviorSubject<ILanguage>({} as any);
    locale$ = this.olocale.asObservable();

    private ostate = new BehaviorSubject<IState>({} as any);
    state$ = this.ostate.asObservable();

    private omode = new BehaviorSubject<IFullScreen>({} as any);
    mode$ = this.omode.asObservable();

    public user = new BehaviorSubject<IUserDetails>({} as any);
    user$ = this.user.asObservable();
    
    public obanner = new BehaviorSubject<IBanner>({} as any);
    banner$ = this.obanner.asObservable();

    constructor(private http: HttpClient,private _uService : UtilityService, private _xService: TranslatorService,
      private _sharedService:SharedService,
      @Inject(BASE_URL) private baseURL: string) {
    }

    private mapstate(data:any) : any{
      var out = <IState>{};
      out.state = data;
      return out;
    }

    setstate(data: any) {
      let set = this.mapstate(data);
      this.ostate.next(set);
    }

    private mapmode(data:any) : any{
      var out = <IFullScreen>{};
      out.display = data;
      return out;
    }

    setmode(data: any) {
        let set = this.mapmode(data);
        this.omode.next(set);
    }

    setUser(data:any){
     let set = <IUserDetails>{}
     set.name = data?.name;
     set.isAdmin = data?.admin;
     this.user.next(set);
    }

    private bannermode(data:any) : any{
      var out = <IBanner>{};
      out.display = data;
      return out;
      }
      
      setbannermode(data: any) {
      let set = this.bannermode(data);
      this.obanner.next(set);
      }
      
    
    setLanguage(data:any){
      this._xService.changeLanguage(data?.language);
      this.olocale.next(data?.language);
     } 

   checkAccess(stateurl:any):boolean {
    stateurl = stateurl.split('/').slice(0, 4).join('/');
    const dataset = this.ostate.getValue().state;
    if(this._uService.isNotNull(dataset)){
      let out = this._uService.findByProperty(dataset, (val: { url: any; }) =>  val.url === stateurl);
      if(this._uService.isNotNull(out)){
          // change to response from roles endpoint
          this._sharedService.setcurrentstate(out?.access);
          return true;
      } else{
          return false;
      }
      } else{
        return false;
      }
   }

   hasAccess(sURL:any): any {
      const stateurl = sURL;
      const url = this.baseURL + "/users/profile"; 
      return this.http.get<any>(`${url}`).pipe(map(response => {
          if (this._uService.isNotEmptyObject(response) && response?.status?.toUpperCase() == HTTPSTATUS.Success) {  
            this.setUser(response?.data);
            this.setLanguage(response?.data || this.defaultLocale);
            this.onSetRoutes(response?.data?.menus);
            return this.checkAccess(stateurl);
          }else {
            return null;
          }
        })
      );
    }


    onSetRoutes(set:any){    
      let mList=MenuList;
      let arr=[];
        mList?.map(x=>{
         let menu=set.filter(o => o.groupId == x.groupId);
         if( menu.length == 1){
           x.access=menu[0].access;
           x.routes.map(y=>{y.access = x.access});
           arr.push(x);
         }else if(menu.length > 1){
          x.access = menu.filter(o=>o.access != 0).length > 0 ?2:0;
          menu.map(i=>{
            x.children.find(o=>o.id==i.id).access = i.access;
            x.children.find(o=>o.id==i.id).routes.map(y=>{y.access = i.access});
          });
          arr.push(x);
         }
        });
        arr.sort((a,b)=>{return a.sortOrderId - b.sortOrderId});
        this.setstate(arr);     
    }


    createSupport(body:any) {
      const url = this.baseURL + `users/create/support`;  
      return this.http.post<any>(`${url}`,body).pipe();
    }
}