import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs'; 
import { BASE_URL } from 'apps/yellow-time/src/environments/environment.factory';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  constructor(private http: HttpClient,@Inject(BASE_URL) private baseURL: string, private _router: Router) {}

  login(body: any): Observable<any> {
    const url =  this.baseURL + `auth/signin`;  
    return this.http.post<any>(`${url}`, body).pipe();
  }

  requestPwd(email: any): Observable<any> {
    const url =  this.baseURL + `auth/requestpwd?email=${email}`;  
    return this.http.put<any>(`${url}`,{}).pipe();
  }

  resetPwd(body: any): Observable<any> {
    const url =  this.baseURL + `auth/resetpwd`;  
    return this.http.put<any>(`${url}`, body).pipe();
  }

  getRefreshToken() { 
    let body = { 'refreshToken': this.getRefreshTkn() };
    const url =  this.baseURL + `users/refreshtoken`;  
    return this.http.post<any>(`${url}`, body).pipe();
  }

  getNavigationAccess() { 
    const url =  this.baseURL + `auth/navigation`;  
    return this.http.get<any>(`${url}`).pipe();
  }

  isLoggedIn(){
   return this.getAccessTkn() == null ? false : true;
  } 

  getAccessTkn(){
    return localStorage.getItem('atktkgyt') || sessionStorage.getItem('atktkgyt');
  } 

  getRefreshTkn(){
    return localStorage.getItem('rtktkgyt') || sessionStorage.getItem('rtktkgyt');
  } 

  setSession(flag:any,set:any){
    if(flag){
      localStorage.setItem('atktkgyt', set?.aTKN);
      localStorage.setItem('rtktkgyt', set?.rTKN);
    }else{
      sessionStorage.setItem('atktkgyt', set?.aTKN);
      sessionStorage.setItem('rtktkgyt', set?.rTKN);
    }
  }

  logout(){
    this._router.navigateByUrl('/auth/login');
  }

  clearSession(){
    if(localStorage.getItem('atktkgyt') != null){
      localStorage.removeItem('atktkgyt');
      localStorage.removeItem('rtktkgyt');
      localStorage.removeItem('language');
      sessionStorage.removeItem('atktkgyt');
      sessionStorage.removeItem('rtktkgyt');
      sessionStorage.removeItem('language');
    }else{
      sessionStorage.removeItem('atktkgyt');
      sessionStorage.removeItem('rtktkgyt');
      sessionStorage.removeItem('language');
    }

  }


}