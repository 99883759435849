
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/generic/guard/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { TranslationResolverService } from './core/generic/translate/translate.resolver';


const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  {  
    path: 'auth', 
    loadChildren: () => import('./module/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'yellow-time',
    component: LayoutComponent, 
    children: [
      {
        path: 'dashboard',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'customers',
        resolve: [TranslationResolverService], 
        loadChildren: () => import('./module/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'maintenance',
        resolve: [TranslationResolverService], 
        loadChildren: () => import('./module/maintenance/maintenance.module').then(m => m.MaintenanceModule)
      },
      {
        path: 'disposition',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/disposition/disposition.module').then(m => m.DispositionModule)
      },
      {
        path: 'disposition/:id',
        resolve: [TranslationResolverService], 
        loadChildren: () => import('./module/disposition/disposition.module').then(m => m.DispositionModule)
      },
      {
        path: 'employees',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/employees/employees.module').then(m => m.EmployeesModule)
      },
      {
        path: 'employees',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/gps-locations/gps-locations.module').then(m => m.GpsLocationsModule)
      },
      {
        path: 'employees',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: 'reports',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'administration',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'administration',
        resolve: [TranslationResolverService], 
        canActivate: [AuthGuard],
        loadChildren: () => import('./module/administration/administration.module').then(m => m.AdministrationModule)
      },
    ]
  },
 { path: '**', redirectTo: '/auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    preloadingStrategy: PreloadAllModules,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    useHash: true
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }