<h2 mat-dialog-title class="fb">{{title | translate}}</h2>
<div>
    <div fxFlex="100" fxLayout="column">
        <p class="p-2 m-2"> {{message | translate}}</p>
    </div>
</div>
<mat-dialog-actions align="end" class="action-btn-bg">
    <button mat-raised-button mat-dialog-close color="secondary" class="cancel-button">{{actionName |
        translate}}</button>
    <button [mat-dialog-close]="true" mat-raised-button color="primary" class="action-btn"
        (click)="onSave()">{{'COMMON.YES' | translate}}</button>
</mat-dialog-actions>