
import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'yellow-time-web-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() set toggle(value:boolean){
    if(value){
      this.onToggle(value);
    } 
  };
  sideNav:boolean;
  sideNavBar:boolean;
  constructor() {
  }

  ngOnInit(): void {
  }

  onClose(){
    this.sideNav = false;
  }
  

  onToggle(value) {
    this.sideNav=value;
    this.sideNavBar=value;
  }
  
}
