import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { UserService } from '../../core/services/users/users.service';
import { HTTPSTATUS } from '../../core/utils/enum/enum';


@Component({
  selector: 'yellow-time-web-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent>
  selectedTabIndex: any = 0;
  selectedLanguage:any; 
  messageControl = new FormControl();

  constructor(
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private _uService:UserService,
    private _nService:NavigationService
  ) { }
  ngOnInit(): void {}
    
  onCancel() {
    this._dialog.closeAll();
  }

  onTabChange(set:any){
    this.selectedTabIndex=set.index;
  }

  onSetLanguage(set:any){
    this.selectedLanguage = set; 
  }

  onSave(){
    switch(this.selectedTabIndex){
      case 0:
      this.onChangeLocale();
      break;
      case 1:
      this.onCreate();
      break;
    }   
  }

  onChangeLocale(){
    this._uService.updateLanguage(this.selectedLanguage).subscribe((res: any) => {
      if (res?.status?.toUpperCase() == HTTPSTATUS.Success) {
          this._dialog.closeAll();
          location.reload();
      }
    })
  }

  onCreate(){
    let set = {"message": this.messageControl.value}
    this._nService.createSupport(set).subscribe((res: any) => {
      if (res?.status?.toUpperCase() == HTTPSTATUS.Success) {
        this._dialog.closeAll();
      }
    })
  }
}
